import { Reviews } from '@athenagroup/components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../markdown/Markdown';

function HomePageWidgetReviews({ title, description, columnCount, items }) {
    const itemsFinal = useMemo(() => (items.map(item => ({
        ...item,
        description: (<Markdown>{item.description}</Markdown>),
    }))), [items]);

    return (
        <Reviews
            title={title}
            description={description}
            columnCount={columnCount}
            items={itemsFinal}
        />
    );
}

HomePageWidgetReviews.disableTitle = true;

HomePageWidgetReviews.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    columnCount: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string.isRequired,
        stars: PropTypes.number,
        authorName: PropTypes.string,
        authorImage: PropTypes.string,
    })),
};

export default HomePageWidgetReviews;
